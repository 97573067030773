<template>
	<v-sheet flat>
		<h2 class="mb-4">
			Export Entire Dataset
		</h2>
		<p>Using the button below, you can export the entire data set in MS Excel .(xslx) format. Data downloaded is formatted in serialised form; most data processing and analysis software accepts this format for importing data.</p>
		<p>Note that you are downloading the data set in its current state, including incomplete responses and responses which participants may or may not edit in future.</p>
		<all-data></all-data>
	</v-sheet>
</template>

<script>
import AllData from "@c/results/AllData";
export default {
	name: "Data",
	components: {
		AllData,
	},
};
</script>
