<template>
	<v-sheet flat>
		<loading v-if="loading"></loading>
		<div v-else>
			<v-sheet>
				<export :columns="headers" :rows="items" name="dma-data" text="Export full dataset"></export>
			</v-sheet>
		</div>
	</v-sheet>
</template>

<style lang="less"></style>

<script>
import Export from "@c/downloads/Export";
import Loading from "@c/ui/Loading";
export default {
	name: "Data",
	components: {
		Loading,
		Export,
	},
	data: () => {
		return {
			loading: true,
		};
	},
	computed: {
		organisations() {
			return this.$store.state.organisations.data;
		},
		categoryOptions() {
			return this.$store.state.categoryOptions.data;
		},
		// order() {

		// 	return this.$store.getters.organisationResponses(this.id)
		// },
		responses() {
			return this.$store.state.sectionResponses.data;
		},
		questions() {
			return this.$store.state.questions.data;
		},
		indicators() {
			return this.$store.state.indicators.data;
		},


		// analyticsCategories() {
		// 	return this.$store.state.analyticsCategories.data;
		// },
		sections() {
			return this.$store.state.sections.data;
		},
		headers() {
			return [
				{ text: "Organisation", value: "organisation" },
				{ text: "Section", value: "section" },
				{ text: "Theme", value: "theme" },
				{ text: "Status", value: "status" },
				{ text: "Question", value: "question" },
				{ text: "Service", value: "service" },
				{ text: "Tag", value: "analytics_category" },
				{ text: "Indicator", value: "indicator" },
				{ text: "Answer", value: "processed_answer" },
				{ text: "Score", value: "score" },
			];
		},
		results() {
			const self = this;
			let organisations = Object.keys(self.organisations);
			return organisations.flatMap((o) => {
				let order = self.$store.getters.organisationResponses(o);
				return order
					.filter((item) => self.responses[item.response])
					.flatMap((item) => {
						let response = self.responses[item.response];
						response.organisation = o;
						return this.mwsurveyutils.processResponse(response);
					})
					.filter((exists) => exists);
			});
		},
		items() {
			const self = this;
			return self.results.map((response) => {
				let r = { ...response };
				r.uid = `${r.section}-${r.response}-${r.question}`;
				let section = self.sections[r.section];
				let theme = self.categoryOptions[section.theme];
				let category = self.categoryOptions[r.service];
				let question = self.questions[r.question];
				let analyticsCategory = self.categoryOptions[question.analytics_category];
				let indicator = question ? self.indicators[question.indicator] : false;
				let questionText = self.$store.getters["questions/getQuestionTexts"](r.question);
				r.section = section ? section.name : "";
				r.indicator = indicator ? indicator.title : ""; 
				r.status = self.mwutils.prettyPrint(r.status, "SENTENCE");
				r.theme = theme ? theme.name : "";
				r.organisation = self.organisations[response.organisation].name;
				r.question = questionText ? questionText.string.trim() : "";
				r.service = category ? category.name : "";
				r.analytics_category = analyticsCategory ? analyticsCategory.name : "";
				return r;
			});
		},
	},
	watch: {
		responses: {
			immediate: true,
			handler() {
				if (this.responses && Object.keys(this.responses).length) {
					this.loading = false;
				}
			},
		},
	},
	created(){
		this.$store.dispatch("categories/fetchById", "analyticsCategory");
	}
};
</script>
